#usp {
  position: relative;
  z-index: 897;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-image: url("./images/service/usp/usp_bg_mobile_pur.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .usp_text_content {
    position: absolute;
    top: 17%;
    left:  0%;
    width: 98vw;
    height: 53%;
    background-image: url("./images/service/usp/usp_text_bg_mobile.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;

    .usp_text_holder {
      position: absolute;
      top: 6.5%;
      left: 5.6%;
      max-width: 75%;

      h2 {
        padding: 0;
        margin: 0;
        font-size: 4.4vw;
        line-height: 4.5vw;
        color: white;
        opacity: .90;
        margin-bottom: 10px;
        margin-top: .0vw;
        width: 80%;
      }

      p {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 3.8vw;
        line-height: 4.3vw;
        letter-spacing: 0px;
        color: white;
        opacity: .90;
      }
  }

    @include media-breakpoint-up(md) {
      top: 11%;
    }
  }

  .advantage_text_content {
    position: absolute;
    top: 53%;
    right:  0;
    width: 94vw;
    height: 32%;
    background-image: url("./images/service/usp/advantage_text_bg_mobile.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    background-size: 100% 70%;

    .advantage_text_holder {
      position: absolute;
      top: 19%;
      left: 13%;

      h2 {
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 4.4vw;
        line-height: 4.5vw;
        color: white;
        opacity: .90;
        margin-bottom: 10px;
        margin-top: .4vw;
      }

      p {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 3.8vw;
        line-height: 4.3vw;
        letter-spacing: 0px;
        color: white;
        opacity: .90;
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/service/usp/usp_bg_pur.png");

    .usp_text_content {
      top: 10.2%;
      left:  0%;
      width: 63vw;
      height: 53%;
      background-image: url("./images/service/usp/usp_text_bg.png");

      .usp_text_holder {
        top: 10%;
        left: 6%;
        max-width: 75%;

        h2 {
          font-size: 2.1vw;
          line-height: 2.3vw;
          letter-spacing: -.1px;
        }

        p {
          font-size: 2.1vw;
          line-height: 2.1vw;
          letter-spacing: -.1px;
        }

      }
    }

    .advantage_text_content {
      background-image: url("./images/service/usp/advantage_text_bg.png");
      background-size: 100%;
      top: 55%;
      right:  0;
      width: 61vw;
      height: 32%;

      .advantage_text_holder {
        top: 27%;
        left: 11.8%;

        h2 {
          font-size: 2.34vw;
          line-height: 2.52vw;
          font-size: 2.1vw;
          line-height: 2.3vw;
          letter-spacing: -.1px;
        }
        p {
          font-size: 2.34vw;
          line-height: 2.34vw;
          font-size: 2.1vw;
          line-height: 2.1vw;
          letter-spacing: -.1px;
        }
      }
    }
  }
}

#portfolio {
  position: relative;
  z-index: 11;
  background-image: url("./images/service/portfolio/portfolio_bg_pur.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  background: #041a3d;
  background-image: -webkit-linear-gradient(45deg, rgba(0, 4, 26, 61) 30%, rgba(24, 54, 100, 1) 100%);
  background-image:    -moz-linear-gradient(45deg, rgba(0, 4, 26, 61) 30%, rgba(24, 54, 100, 1) 100%);
  background-image:      -o-linear-gradient(45deg, rgba(0, 4, 26, 61) 30%, rgba(24, 54, 100, 1) 100%);
  background-image:         linear-gradient(45deg, rgba(0, 4, 26, 61) 30%, rgba(24, 54, 100, 1) 100%);

  margin-top: -10vw;
  margin-top: -30vw;
  padding-top: 10vw;

  .portfolio_overlayer_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("./images/service/portfolio/portfolio_overlayer_bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
  }

  .container {
    padding-bottom: 10vw;

    .row {

      .col {
        text-align: center;
        padding: 0 10%;

        .portfolio_headline {
          margin-top: 36vw;
          margin-bottom: 18vw;

          h2 {
            padding: 0;
            margin: 0;
            color: white;
            opacity: .85;
            text-align: center;
            letter-spacing: -.6px;
            font-size: 6vw;
            line-height: 8.3vw;
            padding: 0vw 5%
          }
          h2.bold {
            font-size: 6vw;
            line-height: 8vw;
            padding: 1vw 15% 0vw 15%;
          }
        }

        .portfolio_spacer {
          margin: 2vw 0vw;

          img {
            width: 20%;
            margin: 0 auto;
            /*border: 1px solid red;*/
          }
        }

        h2 {
          color: white;
          opacity: .85;
          padding: 0;
          margin: 0;
          font-size: 5vw;
          line-height: 8vw;
          padding: 0vw 25%
        }

        p {
          color: white;
          opacity: .85;
          margin: 0;
          min-height: 19vw;
          font-size: 4vw;
          line-height: 5.4vw;
          padding: 0;
          padding-bottom: 10vw;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {

    margin-top: -10vw;

    .container {

      .row {

        .col {
          padding: 0;

          .portfolio_headline {
            margin-top: 11vw;
            margin-bottom: 6.1vw;

            h2 {
              letter-spacing: -.2px;
              font-size:2.6vw;
              line-height: 2.95vw;
              padding: 0vw 18%;
            }
            h2.bold {
              font-size:2.7vw;
              letter-spacing: -1px;
              line-height: 2.95vw;
              opacity: .85;
            }
          }

          .portfolio_spacer {
            margin: 1.1vw 0vw;

            img {
              width: 21%;
            }
          }

          h2 {
            font-size: 1.4vw;
            line-height: 1.7vw;
            letter-spacing: .2px;
          }
          p {
            font-size: 1.4vw;
            line-height: 1.7vw;
            padding: 0% 20%;
          }
        }
      }
    }
  }
}