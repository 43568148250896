*::selection {
  background: $pink;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Source Sans 3",Calibri,Tahoma,sans-serif;;
}

body {
  position: relative;
  width: 100%;
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
  background-color: #091c37;
}

.overflow_hidden {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.position_fixed{
  position: fixed;
}


* {
  box-sizing: border-box;
}

a:focus, input:focus, textarea:focus {
  outline: none;
}

section {
  position: relative;
  background-color: white;
  //overflow: hidden;

  .container-fluid,
  .container {
    padding-right: 0;
    padding-left: 0;

    .row {
      padding-right: 0;
      padding-left: 0;

      .col {
        padding-right: 0;
        padding-left: 0;

        border: 1px solid red;
        border: 0px solid red !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 2px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }
      }
    }
  }
}

#footer_spacer{
background-color: transparent;
}

#footer {
  position: relative;
  position: fixed;
  bottom: 0;
  left: 0;
  background-image: url("./images/footer/footer_bg_mobile_pur.jpg");
  background-size: cover;

  .container-fluid {
    padding-top: 5vw;

    .row {

      .col {
        text-align: center;

        h2 {
          padding: 0;
          margin: 0;
          text-align: center;
          color: $color_blue;
          opacity: .85;
          font-weight: 600;
          font-size: 6vw;
          line-height: 5vw;
          letter-spacing: -.3px;
          padding: 0vw 0vw 4vw 0vw;
          /*border: 1px solid red;*/
        }
        h3 {
          padding: 0;
          margin: 0;
          color: $color_blue;
          font-weight: 600;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;
          font-size: 4.5vw;
          line-height: 4.5vw;
          line-height: 5vw;
          padding: 9vw 0vw 3vw 0vw;
          /*border: 1px solid red;*/
        }
        a,
        p {
          margin: 0px;
          color: $color_dark_gray;
          opacity: .6;
          letter-spacing: 1.8px;
          font-size: 3.5vw;
          line-height: 4.5vw;

          .bold {
            opacity: .9;
          }
        }
        a {
          opacity: 1;
        }

        .footer_certificates {
          //height: 8vw;

          .footer_certificate {
            cursor: pointer;
            height: 25vw;
            width: 37%;
            display: inline-block;
            padding: 0vw .5vw;
            transition: all .3s;

            img{
              height: 100%;
            }

            &:hover {
              transform: rotate(0.1deg) scale(1.05);
            }
          }
        }

        .footer_logos {
          height: 5vw;
          display: inline-block;
          padding: 0px 2px;
          margin-bottom: 15vw;

          .footer_logo {
            text-decoration: none;
            transition: .3s;
            opacity: 1;
            height: 100%;

            img {
              height: 100%;
            }

            &:hover {
              transform: rotate(1deg) scale(1.1);
            }
          }
        }

        .footer_text {
          margin-top:3.3vw;
          text-transform: uppercase;
          width: 100%;
          text-align: center;
          margin: 0 auto;

          a, span {
            display: block;
            width: 100%;
            //border: 1px solid red;
          }

          p,a {
            padding-bottom: 0px;

            &.imprint_button {
              margin-top: 20px;
            }
            &.private_policy_button {
              margin-bottom: 20px;
            }
          }

          .spacer {
            display: none !important;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/footer/footer_bg_pur.jpg");

    .container-fluid {
      padding-top: 0vw;

      .row {

        .col {

          h2 {
            padding: 0;
            margin: 0;
            opacity: .85;
            text-align: center;
            letter-spacing: -.3px;
            font-size: 2.1vw;
            line-height: 5vw;
            padding: 4vw 0vw 0vw 0vw;
          }
          h3 {
            padding: 0;
            margin: 0;
            opacity: .85;
            text-align: center;
            letter-spacing: -.3px;
            font-size: 1.5vw;
            line-height: 3vw;
            padding-top: 3vw;
            padding-bottom: 0vw;
          }
          a,
          p {
            opacity: 0.5;
            font-size: 16px;
            font-size: 0.85vw;
            letter-spacing: 2px;


            .bold {

            }
          }
          a {
            opacity: 1;
          }

          .footer_certificates {
            height: 8vw;

            .footer_certificate {
              width: auto;
              height: 100%;
            }
          }

          .footer_logos {
            height: 2vw;
            margin-bottom: 10vw;
          }

          .footer_text {

            a, span {
              display: inline-block;
              width: auto;
            }

            p,a {
              padding-bottom: 0px;

              &.imprint_button {
                margin-top: 0px;
              }
              &.private_policy_button {
                margin-bottom: 0px;
              }
            }

            .spacer {
              display: inline-block !important;
            }
          }
        }
      }
    }
  }
}