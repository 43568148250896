
#home {
  position:relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 2;
  color: white;
  background: url("./images/company/home/home_bg_pur.jpg");
  background-repeat: no-repeat;
  background-position: center 20%;
  background-size: cover;

  .home_logo {
    display: none;
    position: absolute;
    top: 50px;
    left: 50px;
    transition: .5s;
    width: 12vw;

    img {
      width: 100%;
    }
  }

  .home_stoerer {
    position: fixed;
    left: 50%;
    bottom: 0px;
    bottom: -25px;
    transform: translateX(-50%);
    z-index: 999;
    max-width: 700px;
    width: 100%;
    //height: 157px;
    background: url("./images/company/home/home_stoerer_bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .home_stoerer_icons {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 35px;
      padding-bottom: 15px;

      .stoerer_icon {
        display: inline-block;
        width: 6%;
        margin: 0 5px;
        transition: .5s;

        img {
          width: 100%;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    .home_stoerer_text {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      padding-top: 50px;
      padding-bottom: 10px;
      //border: 1px solid red;

      p {
        width: 100%;
        font-size: 1.3vw;
        line-height: 1.5vw;
        color: white;
        padding-top: 10px;
      }
    }

    @include media-breakpoint-up(md) {
      bottom: -25px;

      .home_stoerer_icons {
        padding-top: 45px;
        padding-bottom: 15px;

        .stoerer_icon {
          width: 5%;
        }
      }
    }
  }

  .home_content {
    position: absolute;
    left: 23%;
    top: 50%;
    transform: translateY(-60%);
    width: 66%;

    .home_text_holder {


      h1 {
        font-size: 8vw;
        line-height: 8.8vw;
        letter-spacing: -1.6px;
        padding-bottom: 10px;
        opacity: .85;
      }

      p {
        color: white;
        font-size: 5vw;
        line-height: 5.4vw;
        letter-spacing: -1.0px;
        opacity: .85;
      }

      .home_button_scroll {
        cursor: pointer;
        position: absolute;
        z-index: 100;
        opacity: .7;
        margin-top: 8vw;
        margin-top: 4vw;
        //padding-top: 4vw;
        margin-left: 10px;
        transition: all .5s;
        width: 17vw;
        animation: 3s infinite animate_arrow;

        @keyframes animate_arrow {
          0%   {
            opacity: 0.7;
            margin-top: 2vw;
          }
          60%  {
            opacity: 0.7;
            margin-top: 2vw;
          }
          80%  {
            opacity: 1;
            margin-top: 3vw;
          }
          100% {
            opacity: 0.7;
            margin-top: 2vw;
          }
        }

        &:hover {
          opacity: 1;
        }

        img {
          width: 100%;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background: url("./images/company/home/home_bg_pur.jpg");
    background-repeat: no-repeat;
    background-position: center 20%;
    background-size: cover;
    min-height: 100%;
    min-height: 600px;

    .home_content {
      transform: translateY(-40%);
      top: 45%;

      .home_text_holder {

        h1 {
          font-size: 3.4vw;
          line-height: 4.6vw;
        }

        p {
          font-size: 2.6vw;
          line-height: 3vw;
          margin-bottom: 0;
        }

        .home_button_scroll {
          margin-top: 6vw;
          width: 6vw;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {

    .home_logo {
      display: block;
    }
  }
}

#technology {
  overflow: hidden;
  position: relative;
  z-index: 897;
  width: 100%;
  min-height: 100%;
  background-color: transparent;
  background-image: url("./images/company/technology/technology_bg_mobile_pur.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  background-size: cover;

  .technology_text_content {
    position: absolute;
    left: 0%;
    background-image: url("./images/company/technology/technology_text_bg_mobile.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100%;
    transition: opacity .7s;
    width: 98vw;
    width: 90vw;
    height: 40%;
    top: 15%;

    @include media-breakpoint-up(md) {
      top: 8%;
    }

    .technology_text_holder {
      position: absolute;
      top: 10%;
      left: 10%;
      width: 70%;

      h2 {
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 4.1vw;
        line-height: 4.2vw;
        letter-spacing: -.6px;
        color: white;
        max-width: 80%;
        opacity: .85;
        margin-bottom: 10px;
        margin-top: .4vw;
      }

      p {
        position: relative;
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 3.8vw;
        line-height: 4.3vw;
        letter-spacing: -.1px;
        color: white;
        opacity: .85;
      }

      .technology_button_holder{
        margin-top: 30px;
        text-align: left;
      }
    }
  }

  .technology_quotation_content {

    .technology_quotation_holder_01 {
      position: absolute;
      width: 70vw;
      top: 48%;
      left: 7.4%;

      p {
        padding: 0;
        margin: 0;
        width: 100%;
        font-size: 5vw;
        line-height: 6.1vw;
        letter-spacing: -.6px;
        color: #002e63;
        opacity: .85;
        margin-bottom: 10px;
        -webkit-transition: margin 1s ease-out;
        -moz-transition: margin 1s ease-out;
        -o-transition: margin 1s ease-out;
        transition: margin 1s ease-out;

        .bold, &.bold {
          display: block;
          margin-top: 0px;
          line-height: 6.2vw;
        }

        .small {
          display: block;
          font-size: 3.7vw;
          line-height: 6.3vw;
        }

        .margin_01, &.margin_01 {
          margin-left:0;
        }
        .margin_02, &.margin_02 {
          margin-left:0;
        }

        @include media-breakpoint-up(md) {
          font-size: 1.82vw;
          line-height: 2.2vw;
          letter-spacing: 0px;

          .bold {
            display: inline;
            line-height: 2.2vw;
          }
          .small {
            display: inline;
            font-size: 1.3vw;
            line-height: 2.2vw;
          }

          .margin_01, &.margin_01 {
            margin-left: 1.2vw;
          }
          .margin_02, &.margin_02 {
            margin-left: 1.8vw;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/company/technology/technology_bg_pur.png");
    background-size: 100%;

    .technology_text_content {
      background-image: url("./images/company/technology/technology_text_bg.png");
      width: 69vw;
      height: 53%;
      top: 10%;
      top: 12%;

      .technology_text_holder {
        left: 16%;
        left: 5%;
        top: 9%;
        width: 76%;

        h2 {
          font-size: 2.6vw;
          line-height: 2.8vw;
          max-width: 100%;
        }
        p {
          font-size: 2.3vw;
          line-height: 2.3vw;
        }
        .technology_button_holder{
          margin-top: 10px;
          text-align: right;
        }
      }
    }

    .technology_quotation_content {

      .technology_quotation_holder_01 {
        width: 46vw;
        top: 53%;
        left: 6.5%;
      }
    }
  }
}

#manager {
  z-index: 12;
  display: block;
  position: relative;
  width: 100%;
  background-image: url("./images/company/manager/manager_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;
  background-color: white;
  margin-top: -27vw;
  margin-top: -20vw;

  .manager_text_holder {
    width: 100%;
    padding: 0;
    padding-top: 4.1vw;
    padding-bottom: 7vw;
    margin: 0;
    text-align: center;
    padding-top: 27vw;

    h2 {
      color: $color_blue;
      font-size: 4.5vw;
      line-height: 6vw;
      margin: 0;
      padding: 0;
      width: 100%;
    }
    h2.bold {
    }

    p {
      color: $color_blue;
      font-size: 3.4vw;
      line-height:4.4vw;
      margin: 0;
      padding: 0;
      padding-top: 1vw;
      padding-bottom: 3vw;
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/company/manager/manager_bg_pur.jpg");
    margin-top: -20vw;

    .manager_text_holder {
      padding-top: 15vw;

      h2 {
        font-size: 2.7vw;
        line-height: 3.5vw;
      }
      p {
        font-size: 1.7vw;
        line-height: 2.2vw;
      }
    }
  }
}

#history {
  position: relative;
  z-index: 11;
  width: 100%;
  background-color: #ebedec;
  padding-bottom: 100px;
  background: $color_blue;

  .history_overlayer_bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("./images/company/history/history_overlayer_bg_mobile.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
  }

  .container-fluid {

    .row {
      position: relative;
      padding: 0px;
      margin: 0;

      .col {
        position: relative;
        height: auto;
        text-align: center;

        .timeline_baseline {
          display: none;
          position: absolute;
          top: 8.0vw;
          left: 0px;
          width: 100%;
          height: 1px;
          background-color: white;
        }
        .timeline_baseline_highlight {
          display: none;
          position: absolute;
          top: 8.0vw;
          left: 0px;
          width:0;
          height: 1px;
          background-color: #569858;
          transition: all 1s;
        }
        .timeline_markers {
          display: none;
          position: relative;
          padding: 0;
          margin: 0;

          .timeline_marker {
            position: relative;
            padding: 0;
            margin: 0;
            width: 20%;
            height: 10.5vw;
            cursor: pointer;
            float:left;
            text-align: center;
            vertical-align: bottom;
            background-color: rgba(1, 1, 1, .0);
            transition: .5s;

            p {
              display: block;
              color: white;
              opacity: .85;
              font-size: 2vw;
              height: 5vw;
              transition: opacity 0.3s ease-out;
              padding-top: 1.25vw;
            }

            &:hover {

              p {
                opacity: 1;
              }
            }
          }

          .timeline_marker:after {
            position: absolute;
            display: block;
            background-color: #b2c7da;
            content: '';
            height: 80px;
            height: 5vw;
            width: 1px;
            left: 50%;
          }
          .timeline_marker.active:after {
            background-color: #569858;
            width: 3px;

            p {
              color: red;
            }
          }
        }

        .year_content {
          position: absolute;
          width: 100%;
          top: 0px;
          left: 0px;

          .year_headline {
            z-index: 18;
            width: 100%;
            position: relative;
            font-weight: 700;
            color: white;
            opacity: .95;
            /*color: red;*/
            text-align: center;
            padding: 0;
            margin: 0;


            font-size: 9.4vw;
            padding-top: 3.6vw;
          }

          .year_copy {
            z-index: 19;
            position: relative;
            font-weight: 300;
            color: white;
            opacity: .85;
            /* color: red;*/
            text-align: center;
            margin: 0;
            max-width: 100%;


            font-size: 5.4vw;
            line-height: 7vw;
            padding: 1.8vw 20% 0px 20%;
          }
        }
        .year_content_1,
        .year_content_2,
        .year_content_3,
        .year_content_4,
        .year_content_5 {
          display: none;
        }
        .year_buttons {
          position: relative;
          width: 100%;

          .year_button {
            cursor: pointer;
            z-index: 20;
            position: absolute;
            top: 0px;
            left: 50%;
            padding-top: 7.5vw;
            width: 25vw;
          }

          .year_button_prev {
            transform: translateX(-150%);

            .year_button_prev_img {
              display: block;
            }
            .year_button_prev_img_hover {
              display: none;
            }

            img {
              width: 100%;
            }

            &:hover {
              .year_button_prev_img {
                display: none;
              }
              .year_button_prev_img_hover {
                display: block;
              }
            }
          }
          .year_button_prev_animate{
            /* opacity: 0.5;*/

            img {
              -webkit-transition: all 1s;
              -moz-transition: all 1s;
              transition: all 1s;

              -webkit-animation: prev_animate 1s; /* Safari 4+ */
              -moz-animation:    prev_animate 1s; /* Fx 5+ */
              -o-animation:      prev_animate 1s; /* Opera 12+ */
              animation:         prev_animate 1s;
            }
          }

          @-webkit-keyframes prev_animate {
            0%   { margin-left: 0px; }
            0% {  margin-left: -20px; }
            100% {  margin-left: 0px; }
          }
          @-moz-keyframes prev_animate {
            0%   { margin-left: 0px; }
            50% {  margin-left: -20px;  }
            100% {  margin-left: 0px;  }
          }
          @-o-keyframes prev_animate {
            0%   { margin-left: 0px; }
            50% {  margin-left: -20px;  }
            100% {  margin-left: 0px; }
          }
          @keyframes prev_animate {
            0%   { margin-left: 0px; }
            50% { margin-left: -20px; }
            100% { margin-left: 0px;}
          }

          .year_button_next {
            transform: translateX(50%);

            .year_button_next_img {
              display: block;
            }
            .year_button_next_img_hover {
              display: none;
            }

            img {
              width: 100%;
            }

            &:hover {
              .year_button_next_img {
                display: none;
              }
              .year_button_next_img_hover {
                display: block;
              }
            }
          }
          .year_button_next_animate{
            /*opacity: 0.5 ;*/

            img {
              -webkit-transition: all 1s;
              -moz-transition: all 1s;
              transition: all 1s;

              -webkit-animation: next_animate 1s; /* Safari 4+ */
              -moz-animation:    next_animate 1s; /* Fx 5+ */
              -o-animation:      next_animate 1s; /* Opera 12+ */
              animation:         next_animate 1s;
            }
          }

          @-webkit-keyframes next_animate {
            0%   { margin-left: 0px; }
            0% {  margin-left: 20px; }
            100% {  margin-left: 0px; }
          }
          @-moz-keyframes next_animate {
            0%   { margin-left: 0px; }
            50% {  margin-left: 20px;  }
            100% {  margin-left: 0px;  }
          }
          @-o-keyframes next_animate {
            0%   { margin-left: 0px; }
            50% {  margin-left: 20px;  }
            100% {  margin-left: 0px; }
          }
          @keyframes next_animate {
            0%   { margin-left: 0px; }
            50% { margin-left: 20px; }
            100% { margin-left: 0px;}
          }
        }

        .composite_logo_ {
          //margin-top: 20%;
          padding-bottom: 4vw;
          //transition: all .5s;

          img {
            width: 48%;
            display: inline;
          }

          &:hover {
           // transform: rotate(0deg) scale(1.05);
          }
        }

        .composite_logo {
          padding-bottom: 10vw;
          padding-top: 6vw;

          -webkit-transition: all .5s;
          -moz-transition: all .5s;
          transition: all .5s;

          img {
            width: 80%;
            max-width: 992px;
          }
        }
        .composite_logo:hover {
          -moz-transform: rotate(0deg) scale(1.05);
          -ms-transform: rotate(0deg) scale(1.05);
          -o-transform: rotate(0deg) scale(1.05);
          -webkit-transform: rotate(0deg) scale(1.05);
        }

        .composite_text {
          text-align: center;
          margin-left: 25%;
          max-width: 50%;


          p {
            position: relative;
            padding: 0;
            margin: 0;
            width: 100%;
            font-size: 5.8vw;
            line-height: 6.9vw;
            font-size: 5.8vw;
            line-height: 6.9vw;
            letter-spacing: -1px;
            color: white;
            opacity: .85;
            padding-bottom: 0px;
          }
        }

        .composite_button {
          text-align: center;
          margin-top: 10vw;
        }
      }

      &.row_timeline {
        padding-top: 15vw;

        .col {
          min-height: 100px;
        }
      }
      &.row_year {

        .col {
          min-height: 250px;
        }
      }
      &.row_composite {
        padding-top: 24vw;
        padding-top: 12vw;
      }
    }
  }

  @include media-breakpoint-up(md) {

    .history_overlayer_bg{
      height: 100%;
      background-image: url("./images/company/history/history_overlayer_bg.png");
    }

    .container-fluid {

      .row {

        .col {

          .timeline_baseline,
          .timeline_baseline_highlight,
          .timeline_markers {
            display: block;
          }

          .year_content {

            .year_headline {
              font-size: 4.7vw;
              padding-top: 1.8vw;
            }
            .year_copy {
              font-size: 2.6vw;
              line-height: 3.5vw;
              padding:1.8vw 20% 0px 20%;
            }
          }
          .year_buttons {

            .year_button_prev {
              padding-top: 3.75vw;
              width: 12.5vw;
            }
            .year_button_next {
              padding-top: 3.75vw;
              width: 12.5vw;
            }
          }

          .composite_logo {
            padding-bottom: 6vw;

            img {
              width: 40%;
            }
          }

          .composite_text {

            p {
              font-size: 2.8vw;
              line-height: 3.4vw;

              font-size: 2.2vw;
              line-height: 2.5vw;
            }
          }

          .composite_button {
            margin-top: 5vw;
          }
        }

        &.row_timeline {
          padding-top: 14.7vw;
        }
        &.row_year {

          .col {
            min-height: 150px;
          }
        }
        &.row_composite {
          padding-top: 4vw;
        }
      }
    }
  }
}