#career {
  position: relative;
  z-index: 897;
  background-color: transparent;
  background-image: url("./images/career/home/career_bg_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .container {
    position: relative;
    padding-top: 31.6vw;
    padding-top: 25vw;
    padding-top: 30vw;

    .row {

      .col {
        text-align: center;

        h2 {
          padding: 0% 10%;
          margin: 0;
          color: white;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;
          font-size: 4.5vw;
          line-height: 4.6vw;
          padding: 0vw 10% 0vw 10%;

          &.bold {
            font-weight: 600;
            font-size: 5vw;
            line-height: 6vw;
            padding: 0vw 6% 8vw 6%;
          }

          &.semibold {
            margin-top: 10vw;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/career/home/career_bg.png");

    .container {
      padding-top: 11.65vw;

      .row {

        .col {

          h2 {
            font-size: 2.2vw;
            line-height: 2.2vw;
            padding: 0 10%;
            padding-bottom: 3vw;

            &.bold {
              font-size: 2.2vw;
              line-height: 4vw;
              padding: 0;
              padding-bottom: 3vw;
            }
            &.semibold {
              margin-top: 0vw;
              font-size: 2.2vw;
              line-height: 2.2vw;
            }
          }
        }
      }
    }
  }
}

#careeradvantages {
  position: relative;
  z-index: 895;
  width: 100%;
  height: 100%;
  background-image: url("./images/career/careeradvantages/careeradvantages_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .container {
    padding-top: 35vw;

    @include media-breakpoint-up(md) {
      padding-top: 25vw;
    }

    .row {

      .col {
        text-align: center;

        h2 {
          padding: 0% 10%;
          margin: 0;
          color: $color_blue;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;

          font-size: 4.5vw;
          line-height: 4.6vw;
          padding: 0vw 10% 0vw 10%;
          margin-bottom: 5vw;
        }

        ul {
          list-style: none;
          margin-bottom: 5vw;
          padding-left: 0px;

          li {
            padding: 0% 10%;
            margin: 0;
            color: $color_blue;
            opacity: .85;
            text-align: center;
            letter-spacing: -.3px;
            padding-bottom: 15px;
            font-size: 3.4vw;
            line-height: 3.4vw;

            span {
              color: $color_green;
            }
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/career/careeradvantages/careeradvantages_bg.jpg");

    .container {
      padding-top: 11.65vw;

      .row {

        .col {

          h2 {
            font-size: 2.2vw;
            line-height: 2.2vw;
            padding-bottom: 30px;
            margin-bottom: 2vw;
          }

          ul {

            li {
              font-size: 1.8vw;
              line-height: 2.2vw;
              padding-bottom: 30px;
            }
          }
        }
      }
    }
  }
}

#careerfooter {
  position: relative;
  z-index: 895;
  width: 100%;
  //height: 100%;
  background-image: url("./images/career/footer/career_footer_bg_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: transparent;

  .container {
    padding-bottom: 10vw;
    padding-top: 10vw;

    .row {

      .col {
        text-align: center;

        .career_button {
          padding: 50px 0px;
        }

        h2 {
          padding: 0% 10%;
          margin: 0;
          color: white;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;
          font-size: 4.5vw;
          line-height: 4.6vw;
          padding: 4vw 10% 0vw 10%;

          .bold {

          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/career/footer/career_footer_bg.png");

    .container {
      padding-top: 0vw;

      .row {

        .col {

          h2 {
            font-size: 2.2vw;
            line-height: 2.2vw;
            padding-top: 8vw;
          }
        }
      }
    }
  }
}