
/* source-sans-3-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/source-sans-3-v15-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-200italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 200;
  src: url('./fonts/source-sans-3-v15-latin-200italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/source-sans-3-v15-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/source-sans-3-v15-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/source-sans-3-v15-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/source-sans-3-v15-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 500;
  src: url('./fonts/source-sans-3-v15-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/source-sans-3-v15-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 600;
  src: url('./fonts/source-sans-3-v15-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/source-sans-3-v15-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/source-sans-3-v15-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/source-sans-3-v15-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* source-sans-3-800italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Source Sans 3';
  font-style: italic;
  font-weight: 800;
  src: url('./fonts/source-sans-3-v15-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


//! set font variables
$font-heading: "Source Sans 3",Calibri,Tahoma,sans-serif;
$font-body: "Source Sans 3",Calibri,Tahoma,sans-serif;

html {
  font-size: 62.5%; // 1rem = 10px, 3.5rem = 35px;
}

body {
  font-family: $font-body;
  font-weight: 300;
  font-size: 1.6rem;
  color: $black;
}

h1, h2, h3, h4, h5, p, a {
  font-weight: 300;
}

.bold {
  font-weight: 600 !important;
}
.semibold {
  font-weight: 500 !important;
}

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 2.6vw;
  line-height: 2.8vw;
  letter-spacing: -1.0px;
  color: white;
  opacity: .85;
}
h2 {
  padding: 0;
  margin: 0;
  width: 100%;
  font-size: 3.4vw;
  line-height: 4.6vw;
  letter-spacing: -1.6px;
  color: white;
  opacity: .85;
}
h3 {

}
h4 {

}
h5 {

}
p, a {
  font-size: 20px;
  font-weight: 300;
  color: $black;
  margin: 0;
  padding-bottom: 30px;
  text-decoration: none;

  &.small {
    font-size: 14px;
  }
  &.underline {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
a {

  &:hover {
    text-decoration: underline;
  }
}

.round_button {
  cursor: pointer;
  display: inline-block;
  padding: 10px 50px;
  border-radius: 20px;
  border: 1px solid white;
  color: white;
  transition: .5s;

  &:hover {
    background-color: white;
    color: $middle_blue;
  }
}

.button_holder {
  text-align: center;
  padding-bottom: 30px;
}