#team {
  position: relative;
  z-index: 897;
  background-color: transparent;
  background-image: url("./images/team/home/team_bg_mobile.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .container {
    padding-top: 31.6vw;

    .row {

      .col {

        h2 {
          margin: 0;
          color: white;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;
          font-size: 4.5vw;
          line-height: 4.6vw;
          padding: 0vw 10% 8vw 10%;
        }
        h2.bold {
          font-size: 5vw;
          line-height: 6vw;
          padding: 0vw 25% 8.2vw 25%;
        }
        h2.semibold {
          padding-top: 9vw;
          max-width: 70%;
          margin-left: 15%;
          font-weight: 100;
          font-size: 5vw;
          line-height: 6.5vw;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/team/home/team_bg.png");

    .container {
      padding-top: 11.65vw;

      .row {

        .col {

          h2 {
            font-size: 2.2vw;
            line-height: 2.6vw;
            padding-bottom: 30px;
          }
          h2.bold {
            font-size: 2.2vw;
            line-height: 2.6vw;
            line-height: 4vw;
            padding: 0vw 10% 0vw 10%;
            padding-bottom: 30px;
          }
          h2.semibold {
            padding-top: 0;
            font-size: 2.2vw;
            line-height: 2.6vw;
          }
        }
      }
    }
  }
}

#teamansprechpartner {
  position: relative;
  z-index: 895;
  width: 100%;
  height: 100%;
  background-image: url("./images/team/teamansprechpartner/teamansprechpartner_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .container::after, .container::before {
    display: table;
    content: " ";
  }

  .container {
    position: relative;
    height: 100%;

    .teamansprechpartner_button {
      cursor: pointer;
      z-index: 100;
      position: absolute;
      left: 50%;
      bottom: -18px;
      margin-left: -160px;
      width: 320px;
      height: 38px;
      text-align: center;
      padding: 0;
      font-size: 18px;
      line-height: 32px;
      color: white;
      background-color: $color_blue;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
      transition: background 0.3s ease-out;

      .light_blue {
        color: $color_green;
      }

      &:hover {
        background-color: $color_green;

        .light_blue {
          color: $color_blue;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/team/teamansprechpartner/teamansprechpartner_bg.jpg");
  }
}
#teamansprechpartner_detail {
  display: none;
  z-index: 894;
  position: relative;
  width: 100%;
  background-color: white;

  .container-fluid {

    .row {
      padding-bottom: 20px;

      .col {
        height: 75vw;
        background: white;
        background-image: linear-gradient(0deg, rgba(245, 245, 245, 1) 0%, rgba(255, 255, 255, 1) 100%);
        -webkit-transition: opacity 2s ease-out;
        -moz-transition: opacity 2s ease-out;
        -o-transition: opacity 2s ease-out;
        transition: opacity 2s ease-out;

        &.col_empty {
          display: none;

          @include media-breakpoint-up(xl) {
            display: block;

          }
        }

        // background blue
        //background: #185687;
        //background-image:         linear-gradient(45deg, rgba(0, 74, 125, 1) 30%, rgba(0, 160, 214, 1) 100%);

        .team_member {
          display: block;
          width: 100%;
          height: 100%;
          float: left;
          text-align: center;
          position: relative;
          -webkit-tap-highlight-color: transparent;

          .team_preload {
            display: none !important;
          }

          .image {
            position: relative;
            bottom: 0;
            width: 100%;
            height: 80%;
            display: block;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: auto 100%;
            opacity: 1;
            transition: opacity .2s ease-in-out;
            //border: 1px solid red;

            img {
              display: none;
            }
          }

          .text {
            position: relative;
            width: 100%;
            height: 20%;
            padding: 0;
            text-align: center;
            color: $color_blue;
            opacity: 1;
            -o-transition: all .3s ease-in-out;
            -moz-transition: all .3s ease-in-out;
            -webkit-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out;
            background-color: white;
            padding: 2vw 0 7vw 0;
            //border: 1px solid red;

            h3 {
              font-size: 20px;
              line-height: 22px;
              font-size: 3.3vw;
              line-height: 3.3vw;
              margin: 0;
              color: $color_blue;
            }

            p {
              font-size: 18px;
              line-height: 22px;
              font-size: 3.3vw;
              line-height: 4.2vw;
              color: $color_blue;
              padding-bottom: 0px;
              margin-bottom: 0px;
            }

            a {
              display: block;
              margin: 0px;
              padding: 0px;
              font-size: 16px;
              line-height: 22px;
              font-size: 2.9vw;
              line-height: 3.3vw;
              color: $color_green;
            }
          }


        }
      }
    }
  }

  .teamansprechpartner_detail_button {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    text-align: center;
    padding: 0;
    color: white;
    background-color: $color_green;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;

    -o-transition: background 0.3s ease-out;
    -mox-transition: background 0.3s ease-out;
    -webkit-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;

    img {
      width: 100%;
    }
  }

  @include media-breakpoint-up(md) {

    .container-fluid {

      .row {

        .col {

          .team_member {

            .image{
              //border: 1px solid green;
            }
            .text {

              h3 {
                font-weight: 500;
                font-size: 20px;
                line-height: 22px;
                margin: 0;
                color: $color-blue;
              }
              p {
                font-weight: 300;
                font-size: 18px;
                line-height: 22px;
                color: $color-blue;
                padding-bottom: 0px;
                margin-bottom: 0px;
              }
              a {
                display: block;
                margin: 0px;
                padding: 0px;
                font-weight: 400;
                font-size: 16px;
                line-height: 22px;
                color: $color-green;
              }
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {

    .container-fluid {

      .row {

        .col {
          height: 50vw;

          .team_member {

            .image {
              height: 80%;
            }
            .text {
              height: 20%;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {

    .container-fluid {

      .row {
        padding-bottom: 0px;

        .col {
          height: 410px;

          .team_member {

            .image {
              position: absolute;
              height: 390px;
            }
            .text{
              position: absolute;
              top: 50%;
              opacity: 0;
              height: auto;
              background-color: transparent;
              padding: 0;
            }
            &:hover {
              cursor: help;

              .image {
                opacity: .1
              }
              .text {
                opacity: 1;
                top: 40%;
              }
            }
          }
        }
      }
    }
  }
}

#teamfachbereiche {
  position: relative;
  z-index: 893;
  width: 100%;
  height: 100%;
  background-image: url("./images/team/teamfachbereiche/teamfachbereiche_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  .container {
    position: relative;
    height: 100%;

    .teamfachbereiche_button {
      cursor: pointer;
      z-index: 100;
      position: absolute;
      left: 50%;
      bottom: -18px;
      margin-left: -160px;
      width: 320px;
      height: 38px;
      text-align: center;
      padding: 0;

      font-size: 18px;
      line-height: 32px;

      color: white;
      background-color: $color_blue;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;

      -o-transition: background 0.3s ease-out;
      -mox-transition: background 0.3s ease-out;
      -webkit-transition: background 0.3s ease-out;
      transition: background 0.3s ease-out;

      .light_blue {
        color: $color_green;
      }

      &:hover {
        background-color: $color_green;

        .light_blue {
          color: $color_blue;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-image: url("./images/team/teamfachbereiche/teamfachbereiche_bg.jpg");
  }
}
#teamfachbereiche_details {
  display: none;
  z-index: 892;
  position: relative;
  width: 100%;
  background-image: url("./images/team/teamfachbereichedetails/teamfachbereichedetails_bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  .teamfachbereiche_details_overlayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: #041a3d;
    background: -webkit-linear-gradient(0deg, rgba(0, 4, 26, .9) 0%, rgba(24, 54, 100, .0) 80%);
    background:    -moz-linear-gradient(0deg, rgba(0, 4, 26, .9) 0%, rgba(24, 54, 100, .0) 80%);
    background:      -o-linear-gradient(0deg, rgba(0, 4, 26, .9) 0%, rgba(24, 54, 100, .0) 80%);
    background:         linear-gradient(0deg, rgba(0, 4, 26, .9) 0%, rgba(24, 54, 100, .0) 80%);

  }

  .container {
    position: relative;
    height: 100%;

    .row {
      height: 100%;

      .texte_container {
        position: absolute;
        left: 0px;
        bottom: 5vw;
        width: 100%;
        height: auto;
      }

      .col {
        position: relative;
        height: auto;

        .text_holder {
          position: relative;
          text-align: center;
          /*
          position: absolute;
          bottom: 16vw;
          left: 50%;
          transform: translateX( -50%);
          text-align: center;
          border: 1px solid green !important;
          */

          h3, a {
            padding: 0;
            margin: 0;
            color: $color_green;
            opacity: .85;
            text-align: center;
            letter-spacing: -.3px;
            font-size: 2vw;
            line-height: 2.5vw;

            font-size: calc(15px + .5vw);
            line-height: calc(15px + .5vw);

            padding-top: 1vw;
            padding-bottom: 0vw;
          }
          h3 {
            padding-bottom: .3vw;
          }
          a {
            color: white;
            font-size: calc(11px + .5vw);
            line-height: calc(11px + .5vw);
          }
        }
      }
    }
  }

  @include media-breakpoint-up(md) {

    .container {

      .row {

          .col {
            height: 100%;
            position: relative;

          }
      }
    }
  }

  .teamfachbereiche_details_button {
    cursor: pointer;
    z-index: 100;
    position: absolute;
    bottom: -24px;
    left: 50%;
    margin-left: -24px;
    width: 48px;
    height: 48px;
    text-align: center;
    padding: 0;
    color: white;
    background-color: $color_green;
    -webkit-border-radius: 24px;
    -moz-border-radius: 24px;
    border-radius: 24px;

    -o-transition: background 0.3s ease-out;
    -mox-transition: background 0.3s ease-out;
    -webkit-transition: background 0.3s ease-out;
    transition: background 0.3s ease-out;

    img {
      width: 100%;
    }

    &:hover {
      background-color: $color_blue;
    }
  }
}

#teambetrieb {
  position: relative;
  z-index: 891;
  width: 100%;
  height: 100%;
  background-image: url("./images/team/teambetrieb/teambetrieb_bg_mobile.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100%;

  @include media-breakpoint-up(md) {
    background-image: url("./images/team/teambetrieb/teambetrieb_bg.jpg");
  }
}

#teamfooter {
  position: relative;
  z-index: 895;
  width: 100%;
  height: 100%;
  background-image: url("./images/team/teamfooter/team_footer_bg.png");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  background-color: transparent;

  .container {
    background-color: transparent;
    width: 100%;
    padding-top: 8vw;

    .row {

      .col {
        text-align: center;

        h2 {
          padding: 0% 10%;
          margin: 0;
          color: white;
          opacity: .85;
          text-align: center;
          letter-spacing: -.3px;
           font-size: 4.5vw;
            line-height: 4.6vw;
          padding: 0vw 10% 0vw 10%;

          @include media-breakpoint-up(md) {
            font-size: 2.2vw;
            line-height: 2.2vw;
          }
        }
      }
    }
  }
}

.row::after, .row::before {
  display: table;
  content: " ";
}