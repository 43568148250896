//? set your brand colors

$white: #fff;
$black: #042d37;
$dark_blue: #44899b;
$middle_blue: #87c4d2;
$light_blue: #abe3ef;
$light_gray: #f0f0f0;

.text_black {
  color: $black;
}
.text_dark_blue {
  color: $dark_blue;
}
.text_middle_blue {
  color: $middle_blue;
}
.text_light_blue {
  color: $light_blue;
}
.text_light_gray {
  color: $light_gray;
}


$color_blue : #041a3d;
$color_green : #52ae32;
$color_gray : #91a2ac;
$color_dark_gray : #041a3d;
$color_lightgray : #d1dadf;

$slick-font-path: '~slick-carousel/slick/fonts/';
$slick-loader-path: "~slick-carousel/slick/";
