#page_indicator {
  z-index: 899;
  position: fixed;
  right: -1px;
  bottom: -60px;
  width: 170px;
  height: 38px;

  opacity: .9;

  background-image: url("./images/navigation/page_indicator_bg3.svg");
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  -webkit-transition: width .5s;
  -moz-transition: width .5s;
  transition: width .5s;

  -webkit-transition: all .8s;
  -moz-transition: all .8s;
  transition: all .8s;

  .page_indicator_text {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 160px;

    font-family: "MetaCompPro-Light W04 Regular",Calibri,Tahoma,sans-serif;
    color: white;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    white-space:nowrap;
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 2px 0px;
  }
}
.page_indicator_show {
  bottom: -3px !important;
}