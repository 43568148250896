.navbar {
  position: fixed;
  top: -2px !important;
  z-index: 901;
  width: 101%;
  height: 56px;
  background-color: rgba(255, 255, 255, .95);
  box-shadow: 0 0 0px transparent;
  overflow: visible;
  border: 0px;
  -o-transition: all 0.5s ease-out;
  -mox-transition: all 0.5s ease-out;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
  padding: 0;
  margin: 0;

  .container-fluid {
    padding-right: 0;
    padding-left: 0;
    margin-left: -1px;

    .navbar-brand{
      margin: 0;
      padding: 0;
      margin-top: 16px;
      margin-left: 30px;
      width: 200px;
      height: 40px;
      -webkit-transition: background 0.3s ease-out;
      transition: background 0.3s ease-out;


      img {
        width: 100%;
      }
    }

    .navbar-toggle{
      background: none;
      margin-bottom: 5px;
      margin-top: 13px;
      margin-right: 35px;
      color:$color_blue;
      border-color:transparent !important;

      .icon-bar{
        display: block;
        width: 21px;
        height: 1px;
        background:$color_blue /*!important*/;
        margin-top: 4px;

        -webkit-transition: all .35s;
        -moz-transition: all .35s;
        transition: all .35s;
      }
      .icon-bar + .icon-bar{

      }
    }

    #site-nav{
      background-color: rgba(255, 255, 255, .95);
      box-shadow: none;
      margin: 0;

      ul {
        margin-top: 17px;
        margin-top: 24px;
        margin-right: 0px;

        li {
          border-bottom: 0px;
          border: none;
          padding: 5px 0px;
          text-align: center;
          /*padding: 0px 5px 2px 5px;*/

          a {
            display: inline-block;
            font-family: "MetaWeb W07 Book",Calibri,Tahoma,sans-serif;
            padding: 7px 2px 2px 2px;
            margin:0;
            margin-right: 0px;
            font-size: 16px;
            line-height: 25px;
            text-align: center;
            letter-spacing: .2px;
            background: transparent;
            color: $color_blue;
            -webkit-transition: color .1s ease-in;
            -moz-transition: color .1s ease-in;
            transition: color .1s ease-in;
            border: none;
            box-sizing: border-box;
          }
          a:hover {
            color: $color_green;
            text-decoration: none;
          }

          .rollover_image {
            display: none;
            position: relative;
            padding: 0;
            /* width: 86%;*/
            margin: 10px 0px 0px 0px;
            height: 8px;
            min-height: 8px;
            box-sizing: border-box;
            -webkit-transition: all .1s;
            -moz-transition: all .1s;
            transition: all .1s;

            /*border: 1px solid red;*/

            img {
              position: absolute;
              top: 0px;
              left: 0px;
              padding: 0;
              margin: 0;
              min-height: 10px;
              width: 100%;
              min-height: 200%;
            }
          }
          .rollover_image_hightlight {
            margin: 0px 5px 0px 5px;
            margin: 0;
            -webkit-transition: all 0s;
            -moz-transition: all 0s;
            transition: all 0s;
          }
        }
        li.contact{
          padding-right: 0px;
          background-color: rgba(82, 174, 50, .95);
          //margin-top: -5px;

          a{
            font-family: "MetaCompPro-Bold W06 Regular",Calibri,Tahoma,sans-serif;
            margin-top: -3px;
            font-size: 16px;
            line-height: 25px;
            color: white;
            margin-right: 0px;
            background-image: none;
            padding: 7px 2px 2px 2px;

          }
        }
        li.contact a:hover{
          color: white;
          background-image: url("./images/navigation/navigation_jobs_border_over.svg");
          background-repeat: no-repeat;
          background-position: top center;
          background-size: 100% 100%;
        }
        li.active {
          a {
            color: $color_green;
            text-decoration: none;
            cursor: default;
          }
         .rollover_image{
           margin: 0px 5px 0px 5px;
           margin: 0;
           -webkit-transition: all 0s;
           -moz-transition: all 0s;
           transition: all 0s;
         }
        }
        li.language {
          padding-left: 7px;
        }
        li.language a:hover {
          color: $color_green;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    overflow: hidden;

    .container-fluid {
      margin-left: 0px;
      width: 100%;

      .navbar-toggle{
        display: none;
      }

      #site-nav{
        background-color: transparent;

        ul{
          margin-right: 18px;

          li{
            text-align: left;
            padding-right: 15px;

            a {
              display: block;
              padding: 0px 5px 2px 2px;
              color: $color_blue;
              font-size: 12px;
              line-height: 30px;
              text-align: left;

            }

            .rollover_image {
              display: block;
            }
          }

          li.contact {
            background-color: rgba(30, 170, 229, 0);
          }
          li.contact a {
            font-size: 14px;
            color: $color_green;
            background-image: url("./images/navigation/navigation_jobs_border.svg");
            background-repeat: no-repeat;
            background-position: top center;
            background-size: 100% 100%;
            padding: 8px 15px 5px 25px;
          }
        }
      }
    }
  }
}

// home page top
.navbar.intro {
  background-color: transparent;
  height: 70px;

  .container-fluid {
    padding-top: 10px;

    .navbar-brand{
      display: none;
    }

    #site-nav {

      ul {

        li {

          a {
            color: white;
          }
          a:hover {
            color: $color_green;
          }

          .rollover_image {
            display: none;
          }
        }

        li.contact a{
          color: $color_green;
        }
        li.contact a:hover{
          color: white;
        }

        li.active a {
          color: $color_green;
        }
      }
    }
  }
}


/* navi */
@media (max-width:900px) and (min-width:768px) {

  #navigation {
    //ackground-color: rgba(0, 255, 255, .95);

    .navbar_container {

      #site-nav {

        ul {
          margin-right: 10px;

          li {

            a {
              padding: 0px 2px 2px 1px;
              font-size: 10px;
            }
          }
          li.contact {

            a {
              font-size: 10px;
            }
          }
        }
      }
    }
  }
}