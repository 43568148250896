.modal-dialog {

  .modal-content{

    background: #185687;
    background: -webkit-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    background:    -moz-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    background:      -o-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    background:         linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    background:         linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    overflow: hidden;

    .modal-body {
      position: absolute;
      top: 0;
      left: 10%;
      width: 80%;
      height: 100%;
      max-width: 100%;
      padding: 0;
      background-color: transparent;
      overflow-x: hidden;

      .text_holder {

        border: 1px solid red;
        border: 0px solid red !important;

        @include media-breakpoint-up(sm) {
          border: 1px solid yellow;
        }
        @include media-breakpoint-up(md) {
          border: 2px solid green;
        }
        @include media-breakpoint-up(lg) {
          border: 1px solid blue;
        }
        @include media-breakpoint-up(xl) {
          border: 1px solid red;
        }

        position: relative;
        width: 100%;
        height: auto;
        margin: auto;
        max-height: 70%;
        margin-top: 15%;
        padding-right: 10px;
        scrollbar-color: white transparent;
        overflow-y: scroll;

        h3 {
          margin: 0px;
          font-size: 3.5vw;
          line-height: 3.8vw;

          letter-spacing: -.5px;
          color: white;
          opacity: .85;
          padding-bottom: 1vw;
          padding-right: 20px;

          .light {
            color: red;
          }
        }
        h4 {

          font-size: 3.0vw;
          line-height: 3.2vw;

          letter-spacing: -.5px;
          color: white;
          opacity: .85;
          padding-top: 2vw;
          padding-bottom: .0vw;
          padding-right: 20px;
        }
        h5 {
          font-size: 2.5vw;
          line-height: 2.9vw;

          letter-spacing: -.5px;
          color: white;
          opacity: .85;
          padding-top: 0vw;
          padding-bottom: 0vw;
          padding-right: 20px;
        }
        a, p {
          font-size: 2.4vw;
          line-height: 2.9vw;
          font-size: 3vw;
          line-height: 3.8vw;

          color: white;
          opacity: .85;
          padding-right: 20px;
          padding-bottom: 10px;

          &.middle {

          }

          &.big {
            margin: 0px;
            padding: 0;
            font-size: 3.5vw;
            line-height: 4.6vw;
            font-size: 5vw;
            line-height: 5.8vw;
            letter-spacing: -.5px;
            color: white;
            opacity: .85;
          }
          .bold,
          &.bold {
          }
        }
        a {
          display: inline-block;
          padding-right: 0px;
        }
        ul {
          color: white;
          padding-bottom: 10px;

          li {

            p{
              padding-bottom: 10px;
            }
          }
        }

        // policy
        .private_policy_link {
          width: calc(100% - 20px);
          position: relative;
          cursor: pointer;
          padding: 10px 30px 10px 10px;
          color: $color_blue;
          background-color: white;
          margin-bottom: 20px;
          border-radius: 5px;

          .icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 20px;
            width: 15px;
            transition: .5s;

            img {
              width: 100%;
            }

            &.rotate {
              transform: translateY(-50%) rotate(90deg);
            }
          }

          &:hover {
            text-decoration: underline;
          }
        }
        .private_policy_content {
          max-height: 0;
          overflow: hidden;
          transition: 1s;
          padding-left: 15px;
          margin-top: 25px;

          &.show {
            margin-bottom: 50px;
          }
        }

        // form
        .container {

          .row {

            .col {
              //border: 1px solid red !important;

              p {
                position: relative;
                padding: 0;
                //padding-top: 50px;
                //padding-top: 150px;
                margin: 0;
                width: 100%;
                font-size: 22px;
                line-height: 30px;
                letter-spacing: .2px;
                color: white;
                opacity: .85;

                .bold {
                }
              }

              form {
                //padding: 50px 20px;
                //padding: 150px 20px;

                .form-group {
                  margin-bottom: 0px;

                  .form_element {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    /*border: 1px solid red;*/

                    label {

                      &.error {
                        display: none !important;
                      }
                    }

                    .form-control {

                      &.error {
                        border: 1px solid red;
                      }
                    }


                    input{
                      border: 1px solid transparent;
                      background-color: rgba(0, 111, 172, 0.9);
                      background-color: rgba(145, 162, 172, 0.9);
                      border-radius: 0px;
                      height: 45px;
                      color: white;
                      width: 100%;
                      margin-bottom: 20px;
                      -webkit-appearance: none;

                      -webkit-box-shadow: none;
                      box-shadow: none;
                    }

                    input[type=checkbox] {
                      float: left;
                      position: relative;
                      cursor: pointer;
                      width: 20px;
                      height: 20px;
                    }
                    input[type=checkbox]:before {
                      content: "";
                      display: block;
                      position: absolute;
                      width: 16px;
                      height: 16px;
                      top: 0;
                      left: 0;
                      border: 0px solid #555555;
                      border-radius: 0px;
                      background-color: rgba(0, 111, 172, 0.9);
                      background-color: rgba(145, 162, 172, 0.9);
                    }
                    input[type=checkbox]:checked:after {
                      content: "";
                      display: block;
                      width: 5px;
                      height: 10px;
                      border: solid white;
                      border-width: 0 2px 2px 0;
                      -webkit-transform: rotate(45deg);
                      -ms-transform: rotate(45deg);
                      transform: rotate(45deg);
                      position: absolute;
                      top: 2px;
                      left: 6px;
                    }

                    label {
                      //display: inline-block;
                      float: left;
                      font-weight: 100;
                      font-size:12px;
                      width: calc(100% - 20px);
                      padding-left: 10px;
                      color: rgba(255, 255, 255, .8);

                      a {
                        color: rgba(255, 255, 255, .8);
                        text-decoration: underline;
                        font-weight: 100;
                        font-size:12px;
                        padding-bottom: 0px;
                      }
                      a:hover {
                        text-decoration: none;
                      }
                    }

                    textarea {
                      border: 0px solid red;
                      background-color: rgba(0, 111, 172, 0.9);
                      background-color: rgba(145, 162, 172, 0.9);
                      border-radius: 0px;
                      height: 220px;
                      color: white;
                      width: 100%;
                      margin-bottom: 20px;
                      -webkit-appearance: none;
                      -webkit-box-shadow: none;
                      box-shadow: none;
                    }

                    input:hover,
                    textarea:hover,
                    input:focus,
                    textarea:focus {
                      outline: 0;
                      box-shadow: none;
                    }

                    .form_button {
                      border: 0px solid red;
                      background-color: rgba(0, 111, 172, 0.9);
                      background-color: rgba(145, 162, 172, 0.9);
                      width: 100%;
                      height: 45px;
                      color: rgb(110, 182, 42);
                      color: white;
                      font-size: 16px;

                      margin-bottom: 20px;
                    }

                    .invalid-feedback {
                      display: none;
                      color: white;
                    }

                    ::placeholder {

                      letter-spacing: .2px;
                      color: rgba(255,255,255,.7);
                    }

                    *:focus::-webkit-input-placeholder { opacity: 0; } /* Chrome <=56, Safari < 10 */
                    *:focus:-moz-placeholder { opacity: 0; } /* FF 4-18 */
                    *:focus::-moz-placeholder { opacity: 0; } /* FF 19-50 */
                    *:focus:-ms-input-placeholder { opacity: 0; } /* IE 10+ */
                    *:focus::placeholder { opacity: 0; } /* Modern Browsers */
                  }
                  .form_element_firstname{
                    width: 33%;
                    float: left;
                    padding-right: 10px;
                  }
                  .form_element_lastname {
                    width: 34%;
                    float: left;
                  }
                  .form_element_firma{
                    width: 33%;
                    float: left;
                    padding-left: 10px;
                  }
                  .form_element_email{
                    width: 80%;
                    float: left;
                    padding-right: 10px;
                  }
                  .form_element_button{
                    width: 20%;
                    float: left;
                    padding-left: 10px;
                  }
                  .form_element_info {
                    clear: both;

                    #form-messages_de {
                      color: white
                    }
                    #form-messages_en {
                      color: white
                    }
                  }

                  .form_element_checkbox {
                    clear: both;
                  }

                  #form-messages {
                    color: white;
                  }
                }

                .form-group.has-error {

                  .form_element {

                    textarea,
                    input{
                      border: 1px solid #1eaae4;
                    }
                  }
                }
              }
            }
          }
        }

        &.width_70 {
          padding-right: 0%;
        }
      }

      .text_holder_contact {
        max-height: 100%;
        padding: 0 5%;
      }

      .text_holder_manager{
        max-height: 60%;
      }

      &.centered {
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        overflow-y: hidden;

        .text_holder {
          margin-top: 0;
          overflow-y: hidden;
        }
      }

      &.form {
        left: 0%;
        width: 100%;
      }

      .modal_close_button {
        cursor: pointer;
        width: 6vw;
        margin-top: 3vw;
        transition: all .5s;
        transform: rotate(0deg) scale(1);

        img {
          width: 100%;
        }

        &:hover {
          transform: rotate(90deg) scale(1);
        }

        &.form {
          position: absolute;
          top: 0px;
          right: 50px;
        }
      }

      @include media-breakpoint-up(sm) {

        .text_holder{

          a, p {

            &.big {
              font-size: 3.4vw;
              line-height: 4.7vw;
            }
          }
        }

        .modal_close_button {
          width: 5vw;
        }
      }
      @include media-breakpoint-up(md) {

        .text_holder{

          h3 {
            font-size: 2.8vw;
            line-height: 3.1vw;
          }
          h4 {
            font-size: 2.1vw;
            line-height: 2.4vw;
          }
          h5 {
            font-size: 2.0vw;
            line-height: 2.2vw;
          }
          a, p {
            font-size: 1.8vw;
            line-height: 2.2vw;

            &.big {
              font-size: 2.7vw;
              line-height: 3.6vw;
            }
          }
          &.width_70 {
            padding-right: 30%;
          }
        }

        .modal_close_button {
          width: 4vw;
        }
      }
      @include media-breakpoint-up(lg) {
        left: 20%;
        width: 60%;

        .text_holder{

          h3 {
            font-size: 2.0vw;
            line-height: 2.2vw;
          }
          h4 {
            font-size: 1.5vw;
            line-height: 1.7vw;
          }
          h5 {
            font-size: 1.4vw;
            line-height: 1.6vw;
          }
          a, p {
            font-size: 1.3vw;
            line-height: 1.5vw;

            &.big {
              font-size: 1.9vw;
              line-height: 2.6vw;
            }
          }
          &.width_70 {
            padding-right: 30%;
          }
        }

        .modal_close_button {
          width: 3vw;
        }
      }
    }

    .foto_holder {
      position: absolute;
      bottom: 0;
      right: 10%;
      width: 40%;

      img {
        width: 100%;
      }

      @include media-breakpoint-up(md) {
        width: 30%;
      }
    }

    &.modal-content_form {
      background: #185687;
      background: -webkit-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
      background:    -moz-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
      background:      -o-linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
      background:         linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
      background:         linear-gradient(134deg, rgba(22, 46, 83, 1) 20%, rgba(4, 26, 61, 1) 50%);
    }
  }
}

.fade_from_bottom {
  transform: translateY(110%);
  opacity: 1 !important;
  -webkit-transition: .5s opacity, 1s all 0s;
  -moz-transition: .5s opacity, 1s all 0s;
  -ms-transition: .5s opacity, 1s all 0s;
  -o-transition: .5s opacity, 1s all 0s;
  transition: .5s opacity, 1s all 0s;
  display: block !important;
}
.fade_from_bottom.show {
  opacity: 1;
  transform: translateY(0) !important;
  transform:none;
}

.modal-backdrop {
  background-color: transparent;
}