.rounded_button {
  cursor: pointer;
  display: inline-block;
  position: relative;
  padding: 0px 22px 0px 22px;
  font-size: 10px;
  line-height: 31px;
  color: white;
  opacity: .9;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  transition: background-color .5s;
  transition: .5s;
  text-transform: uppercase;
  font-weight: 600;

  @include media-breakpoint-up(md) {
    padding: 0px 22px 0px 22px;
    font-size: 10px;

    &.big {
      padding: 5px 30px 5px 30px;
      font-size: 13px;
      line-height: 32px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 5px 22px 5px 22px;
    font-size: 12px;
  }

  &.big {
    padding: 10px 40px 10px 40px;
    font-size: 14px;
    line-height: 35px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
  }

  &:hover {
    background-color: rgba(110, 182, 42, .7);
    border: 1px solid  rgba(110, 182, 42, .7);
    color: white;
    opacity: 1;
    text-decoration: none;
  }

  &.blue_cyan {
    border: 0px solid  #061f43;
    background-color: #061f43;
    color: $color_green;

    &:hover {
      background-color: $color_green;
      color: white;
    }
  }

  &.cyan {
    border: 1px solid  $color_green;
    background-color: $color_green;
    color: white;

    &:hover {
      border: 1px solid white;
      background-color: transparent;
      color: white;
    }
  }
}

.info_button {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
  width: 8vw;
  height: 8vw;
  overflow: visible;
  transition: .5s;

  img, svg {
    width: 100%;
  }

  #bg, #text {
     transition: .5s;
    transform-origin: center;
  }
  @include media-breakpoint-up(md) {
    width: 34px;
    height: 34px;
  }
  @include media-breakpoint-up(lg) {
    width: 42px;
    height: 42px;
  }

  &:hover {
    transform: scale(1.2);

    #bg {
      fill: $color_green;
    }
    #text {
      fill: white;
    }
  }

  &.block {
    display: block;
  }
}
.info_button_blue {

  #bg {
    fill: $color_green;
  }
  #text {
    fill: white;
  }
}

.button_spacer {
  display: inline-block;
  vertical-align: middle;
  margin-left: 1.2vw;
  margin-right: 1.2vw;
  height: 40px;
  width: 1px;

  @include media-breakpoint-up(lg) {
    height: 30px;
  }

  img {
    height: 100%;
  }
}